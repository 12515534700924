













import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import MessageBoxMessages from '@/views/chat/MessageBoxMessages.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ChatModuleHelper from '@/components/chat/ChatModuleHelper.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import GroupType from '@/utils/enums/chat/GroupType';
import EntityType from '@/utils/enums/EntityType';
import GUUID from '@/utils/GUUID';

@Component({
  components: { MessageBoxMessages },
})
export default class ExhibitorPortalUserDetailChatWidget extends mixins(
  VueRegisterStoreWidget,
  ChatModuleHelper,
  VueBaseWidget,
) {
  protected baseStoreName = 'ExhibitorPortalUserDetailChatWidgetStore';

  @Prop({
    required: false,
    default: null,
  })
  private creatorUser!: CommunityUser;

  @Prop({
    required: false,
    default: () => [],
  })
  private chatReps!: CommunityUser[];

  @Prop({
    required: false,
    default: null,
  })
  private companyUid!: string;

  @Prop({
    required: false,
    default: null,
  })
  private memberUid!: string;

  private isGroupLoaded = false;

  private get showWidget(): boolean {
    return this.isReadyToDisplay
      && this.chatStoreCreated
      && !!this.creatorUser
      && this.chatReps.length > 0
      && !this.memberUid.includes('%')
      && !this.companyUid.includes('%');
  }

  created(): void {
    this.storeContext = 'EpUserDetailChatWidgetStore';
    this.setDataConfig();
  }

  @Watch('showWidget', { immediate: true })
  private initComponentConfig(): void {
    if (this.showWidget && !this.isGroupLoaded) {
      this.dispatch('paginatedGroups', {
        target: {
          uid: this.companyUid,
        },
        creatorUser: {
          uid: this.memberUid,
        },
      })
        .then(() => {
          const groupValidationCallback = (messageGroup: MessageGroup): boolean => messageGroup.groupType !== GroupType.QNA
            && messageGroup.creatorUser?.uid === this.memberUid
            && messageGroup.target?.uid === this.companyUid;

          this.commit(
            'setValidateGroupFn',
            groupValidationCallback,
          );
          const groups = this.getter<MessageGroup[]>('conversationsList');
          if (groups && groups.length > 0) {
            this.commit('setSelectedGroup', groups[0].uid);
          } else {
            const tempGroupId = GUUID.uuidv4();
            this.commit('setNewConversation', MessageGroup.hydrate({
              uid: tempGroupId,
              tempId: tempGroupId,
              users: [...this.chatReps, this.creatorUser],
              target: {
                uid: this.companyUid,
                __typename: EntityType.EXHIBITOR,
              },
              creatorUser: this.creatorUser,
              selected: true,
              messages: [],
            }));
            this.commit('setSelectedGroup', tempGroupId);
          }
        })
        .finally(() => {
          this.isGroupLoaded = true;
        });
    }
  }
}
